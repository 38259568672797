<template>
  <v-container fill-height>
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-alert color="red lighten-1" type="error" v-if="alerts" dense>
            {{ alerts }}
          </v-alert>

          <v-alert color="green lighten-1" type="success" v-if="messages" dense>
            {{ messages }}
          </v-alert>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-card>
            <v-card-title>注册/Sign up</v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-if="!token"
                v-model="requestForm.valid"
                @submit.prevent="handleRequestSubmit"
                lazy-validation
              >
                <v-text-field
                  v-model="requestForm.name"
                  :counter="10"
                  :rules="[
                    (v) => !!v || '',
                    (v) => (v && v.length <= 10) || '姓名过长/Name too long',
                  ]"
                  label="姓名/Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="requestForm.email"
                  :rules="[
                    (v) => !!v || '',
                    (v) => /.+@.+\..+/.test(v) || '错误的电子邮箱/Wrong email',
                  ]"
                  label="电子邮箱/Email"
                  required
                ></v-text-field>

                <v-card-actions class="justify-center">
                  <v-progress-circular
                    v-if="waiting"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-btn
                    type="submit"
                    v-else
                    :disabled="!requestForm.valid || !requestForm.name || !requestForm.email"
                    color="primary"
                    class="mr-4"
                  >
                    提交/Submit
                  </v-btn>
                </v-card-actions>
              </v-form>

              <v-form
                ref="form"
                v-else
                v-model="confirmForm.valid"
                @submit.prevent="handleConfirmSubmit"
                lazy-validation
              >
                <v-text-field
                  v-model="confirmForm.name"
                  :counter="20"
                  :rules="[(v) => !!v || '', (v) => (v && v.length <= 20) || '用户名过长/too long']"
                  label="用户名/Username"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="confirmForm.realname"
                  :counter="20"
                  :rules="[(v) => !!v || '', (v) => (v && v.length <= 20) || '姓名过长/too long']"
                  label="真实姓名/RealName"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="confirmForm.email"
                  :rules="[
                    (v) => !!v || '',
                    (v) => /.+@.+\..+/.test(v) || '错误的电子邮箱/Wrong email',
                  ]"
                  label="电子邮箱/Email"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="confirmForm.phone"
                  :rules="[
                    (v) => !!v || '',
                    (v) => (v && v.length === 11) || '错误的号码/Wrong number',
                  ]"
                  label="手机号码/Phone Number"
                  prefix="+86"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="confirmForm.password"
                  :append-icon="confirmForm.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[
                    (v) => !!v || '',
                    (v) => (v && v.length >= 8) || '最少 8 位/ 8 chars at least',
                  ]"
                  :type="confirmForm.showPassword ? 'text' : 'password'"
                  label="密码/Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="confirmForm.showPassword = !confirmForm.showPassword"
                ></v-text-field>

                <v-text-field
                  v-model="confirmForm.repeatPassword"
                  :append-icon="confirmForm.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[
                    (v) => !!v || '',
                    (v) => (v && v == confirmForm.password) || '密码不一致/Password mismatch',
                  ]"
                  :type="confirmForm.showPassword ? 'text' : 'password'"
                  label="确认密码/Confirm Password"
                  hint="repeat password"
                  counter
                  @click:append="confirmForm.showPassword = !confirmForm.showPassword"
                ></v-text-field>

                <v-card-actions class="justify-center">
                  <v-progress-circular
                    v-if="waiting"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-btn
                    type="submit"
                    v-else
                    :disabled="
                      !confirmForm.valid ||
                      !confirmForm.name ||
                      !confirmForm.realname ||
                      !confirmForm.email ||
                      !confirmForm.phone ||
                      !confirmForm.password ||
                      !token
                    "
                    color="primary"
                    class="mr-4"
                  >
                    提交/Submit
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios");
const requests = axios.create({
  timeout: 20000,
});

export default {
  data: () => ({
    token: null,
    alerts: null,
    messages: null,
    waiting: false,
    requestForm: {
      valid: false,
      name: "",
      email: "",
    },
    confirmForm: {
      valid: false,
      name: "",
      realname: "",
      email: "",
      phone: "",
      password: "",
      repeatPassword: "",
      showPassword: false,
    },
  }),
  created() {
    this.token = this.$route.query.token;
  },
  methods: {
    handleRequestSubmit() {
      this.waiting = true;
      requests
        .post(`/api/auth/signup/request`, {
          name: this.requestForm.name,
          email: this.requestForm.email,
        })
        .then((response) => {
          this.waiting = false;
          this.alerts = null;
          const data = response.data;
          this.messages = data.message;
        })
        .catch((err) => {
          this.waiting = false;
          this.messages = null;
          if (err.response == null) {
            alert("unkown server error");
            return;
          }
          const data = err.response.data;
          if (err.response.status >= 500) {
            this.alerts = "server error";
          } else if (err.response.status >= 400) {
            if (data != null) {
              this.alerts = data.detail;
            } else {
              this.alerts = "bad request";
            }
          } else {
            this.alerts = "unkown error";
          }
        });
    },
    handleConfirmSubmit() {
      this.waiting = true;
      requests
        .post(`/api/auth/signup/confirm`, {
          name: this.confirmForm.name,
          realname: this.confirmForm.realname,
          email: this.confirmForm.email,
          phone: this.confirmForm.phone,
          password: this.confirmForm.password,
          token: this.token,
        })
        .then((response) => {
          this.waiting = false;
          this.alerts = null;
          let data = response.data;
          alert(data.message);
          this.$router.push("/");
        })
        .catch((err) => {
          this.waiting = false;
          this.messages = null;
          if (err.response == null) {
            alert("未知服务器错误/Unkown server error");
            return;
          }
          const data = err.response.data;
          if (err.response.status >= 500) {
            this.alerts = "服务器错误/Server error!";
          } else if (err.response.status >= 400) {
            if (data != null) {
              this.alerts = data.detail;
            } else {
              this.alerts = "非法请求/Bad request!";
            }
          } else {
            this.alerts = "未知错误/Unkown error!";
          }
        });
    },
  },
};
</script>
