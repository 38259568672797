var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[(_vm.alerts)?_c('v-alert',{attrs:{"color":"red lighten-1","type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.alerts)+" ")]):_vm._e(),(_vm.messages)?_c('v-alert',{attrs:{"color":"green lighten-1","type":"success","dense":""}},[_vm._v(" "+_vm._s(_vm.messages)+" ")]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-card',[_c('v-card-title',[_vm._v("注册/Sign up")]),_c('v-card-text',[(!_vm.token)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleRequestSubmit.apply(null, arguments)}},model:{value:(_vm.requestForm.valid),callback:function ($$v) {_vm.$set(_vm.requestForm, "valid", $$v)},expression:"requestForm.valid"}},[_c('v-text-field',{attrs:{"counter":10,"rules":[
                  (v) => !!v || '',
                  (v) => (v && v.length <= 10) || '姓名过长/Name too long',
                ],"label":"姓名/Name","required":""},model:{value:(_vm.requestForm.name),callback:function ($$v) {_vm.$set(_vm.requestForm, "name", $$v)},expression:"requestForm.name"}}),_c('v-text-field',{attrs:{"rules":[
                  (v) => !!v || '',
                  (v) => /.+@.+\..+/.test(v) || '错误的电子邮箱/Wrong email',
                ],"label":"电子邮箱/Email","required":""},model:{value:(_vm.requestForm.email),callback:function ($$v) {_vm.$set(_vm.requestForm, "email", $$v)},expression:"requestForm.email"}}),_c('v-card-actions',{staticClass:"justify-center"},[(_vm.waiting)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":!_vm.requestForm.valid || !_vm.requestForm.name || !_vm.requestForm.email,"color":"primary"}},[_vm._v(" 提交/Submit ")])],1)],1):_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleConfirmSubmit.apply(null, arguments)}},model:{value:(_vm.confirmForm.valid),callback:function ($$v) {_vm.$set(_vm.confirmForm, "valid", $$v)},expression:"confirmForm.valid"}},[_c('v-text-field',{attrs:{"counter":20,"rules":[(v) => !!v || '', (v) => (v && v.length <= 20) || '用户名过长/too long'],"label":"用户名/Username","required":""},model:{value:(_vm.confirmForm.name),callback:function ($$v) {_vm.$set(_vm.confirmForm, "name", $$v)},expression:"confirmForm.name"}}),_c('v-text-field',{attrs:{"counter":20,"rules":[(v) => !!v || '', (v) => (v && v.length <= 20) || '姓名过长/too long'],"label":"真实姓名/RealName","required":""},model:{value:(_vm.confirmForm.realname),callback:function ($$v) {_vm.$set(_vm.confirmForm, "realname", $$v)},expression:"confirmForm.realname"}}),_c('v-text-field',{attrs:{"rules":[
                  (v) => !!v || '',
                  (v) => /.+@.+\..+/.test(v) || '错误的电子邮箱/Wrong email',
                ],"label":"电子邮箱/Email","required":""},model:{value:(_vm.confirmForm.email),callback:function ($$v) {_vm.$set(_vm.confirmForm, "email", $$v)},expression:"confirmForm.email"}}),_c('v-text-field',{attrs:{"rules":[
                  (v) => !!v || '',
                  (v) => (v && v.length === 11) || '错误的号码/Wrong number',
                ],"label":"手机号码/Phone Number","prefix":"+86","required":""},model:{value:(_vm.confirmForm.phone),callback:function ($$v) {_vm.$set(_vm.confirmForm, "phone", $$v)},expression:"confirmForm.phone"}}),_c('v-text-field',{attrs:{"append-icon":_vm.confirmForm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                  (v) => !!v || '',
                  (v) => (v && v.length >= 8) || '最少 8 位/ 8 chars at least',
                ],"type":_vm.confirmForm.showPassword ? 'text' : 'password',"label":"密码/Password","hint":"At least 8 characters","counter":""},on:{"click:append":function($event){_vm.confirmForm.showPassword = !_vm.confirmForm.showPassword}},model:{value:(_vm.confirmForm.password),callback:function ($$v) {_vm.$set(_vm.confirmForm, "password", $$v)},expression:"confirmForm.password"}}),_c('v-text-field',{attrs:{"append-icon":_vm.confirmForm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                  (v) => !!v || '',
                  (v) => (v && v == _vm.confirmForm.password) || '密码不一致/Password mismatch',
                ],"type":_vm.confirmForm.showPassword ? 'text' : 'password',"label":"确认密码/Confirm Password","hint":"repeat password","counter":""},on:{"click:append":function($event){_vm.confirmForm.showPassword = !_vm.confirmForm.showPassword}},model:{value:(_vm.confirmForm.repeatPassword),callback:function ($$v) {_vm.$set(_vm.confirmForm, "repeatPassword", $$v)},expression:"confirmForm.repeatPassword"}}),_c('v-card-actions',{staticClass:"justify-center"},[(_vm.waiting)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":!_vm.confirmForm.valid ||
                    !_vm.confirmForm.name ||
                    !_vm.confirmForm.realname ||
                    !_vm.confirmForm.email ||
                    !_vm.confirmForm.phone ||
                    !_vm.confirmForm.password ||
                    !_vm.token,"color":"primary"}},[_vm._v(" 提交/Submit ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }